body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
 
.customScroll::-webkit-scrollbar
{
	width: 12px;
	background-color: transparent;
}
 
.customScroll::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px #7781FF;
	background-color: #7781FF;
}

.customScroll {
	/* Internet Explorer 10+ */
    -ms-overflow-style: none; 
	/* Firefox */
    scrollbar-width: none;  
}

.required {
  color: #FF4F4F;
}

@font-face { 
  font-family: 'Museo Sans Rounded';
  src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-500.eot') format('eot');
  src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-500.ttf') format('ttf');
  src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-500.woff') format('woff');
  src: url('assets/fonts/museo-sans-rounded/MuseoSansRounded-500.woff2') format('woff2');
}


.dropzone>div:first-of-type {
  border: 2px dashed #003AD6;
  border-radius: 2px;
}

.MuiAutocomplete-noOptions {
  padding: 0px
}

.no-horizontal-scroll {
  overflow-x: hidden;
  max-width: 100vw;
}